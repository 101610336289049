<template>
    <Setup-Step
        @next="f => f()"
        :canNavigate="false"
        hideSkip
        hideYesNo
        :loadingMsg="loadingMsg"
        :settings="settings"
        showNext
        :stage.sync="stage"
        step="courier-order-move"
        title="Courier Order Planning">
        <template v-slot:body>
            <BT-Courier-Cycle-Header showTrimmings highlightMovement title="Courier Pipeline: Create Movements" />

            <v-divider class="my-4" />

            <Setup-Block>
                <BT-Entity
                    ignoreID
                    inline
                    navigation="courier-settings"
                    single>
                    <template v-slot="{ item, save }">
                        <v-subheader>Create Route</v-subheader>

                        <p>
                            Once a route is planned, you can create each leg in the route.  
                            These legs are called movements.  Movements contain release and delivery information.  
                            You can configure BlitzIt Web to do this automatically or to await for user input.  
                            This can be set globally for every client or individually for each client.
                        </p>

                        <v-row>
                            <v-col cols="8">
                                <BT-Square-Check
                                    @change="save"
                                    falseClass="success--text"
                                    falseIcon="mdi-account-circle-outline"
                                    falseValue="Agreements"
                                    isEditing
                                    :label="item.routeMovementGuide == 'Settings' ? 'Apply To Every Client' : 'Different For Each Client'"
                                    outlined
                                    trueIcon="mdi-earth"
                                    trueValue="Settings"
                                    v-model="item.routeMovementGuide" />
                            </v-col>
                            <v-col cols="4" class="align-self-center">
                                <v-slide-x-transition hide-on-leave>
                                    <BT-Square-Check
                                        v-if="item.routeMovementGuide == 'Settings'"
                                        @change="save"
                                        falseClass="success--text"
                                        falseIcon="mdi-boom-gate"
                                        falseValue="Manual"
                                        isEditing
                                        :label="item.routeMovementTrigger"
                                        outlined
                                        trueIcon="mdi-boom-gate-up"
                                        trueValue="Auto"
                                        v-model="item.routeMovementTrigger" />
                                    <p v-else>
                                        (Define this when you add new clients)
                                    </p>
                                </v-slide-x-transition>
                            </v-col>
                        </v-row>
                        
                        <div v-if="settings.data.courieringForSelf">
                            <v-divider class="my-4" />

                            <v-subheader>Self-Couriering</v-subheader>

                            <v-row>
                                <!-- <v-spacer /> -->
                                <v-col cols="4" class="align-self-center">
                                    <v-slide-x-transition hide-on-leave>
                                        <BT-Square-Check
                                            @change="save"
                                            falseClass="success--text"
                                            falseIcon="mdi-boom-gate"
                                            falseValue="Manual"
                                            isEditing
                                            :label="item.routeMovementTrigger"
                                            outlined
                                            trueIcon="mdi-boom-gate-up"
                                            trueValue="Auto"
                                            v-model="item.routeMovementTrigger" />
                                    </v-slide-x-transition>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </BT-Entity>

            </Setup-Block>
            
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Order-Move-Setup',
    components: {
        BTCourierCycleHeader: () => import('~components/BT-Courier-Cycle-Header.vue'),
        SetupBlock: () => import('~home/setup/Setup-Block.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue')
    },
    data() {
        return {
            loadingMsg: null,
            newItem: {},
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: {
            type: Object,
            default: null
        },
    }
}
</script>